#base64 {
    height: calc(100vh - 131px);
    margin: 10px 0;
    resize: none;
}
#base64Text {
    height: calc(100vh - 131px);
    margin: 10px 0;
    resize: none;
}
