#json {
    height: calc(100vh - 131px);
    margin: 10px 0;
    resize: none;
}
#jsonResult {
    height: calc(100vh - 131px);
    margin: 10px 0;
}
.string { color: green; }
.number { color: darkorange; }
.boolean { color: blue; }
.null { color: magenta; }
.key { color: red; }
